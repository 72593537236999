@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap);
body {
  margin: 0;
  font-family: 'Rubik';
  /* font-family: BrokenConsole-Bold,Helvetica,sans-serif; */
  color: #254cdd;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url(./Tileclops_small2.png); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Minter {
  max-width: 80vh;
  /* max-height: 80vh; */
  padding: 20px 100px;
  margin: 0 auto;
  text-align: left;

}

input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  font-size: 16px;
  border-color: rgba(4, 4, 5, 0.1);
  background-color: transparent;
  line-height: 32px;
}

input:focus {
  outline: none;
  border-color: rgba(4, 4, 5, 0.4);
}

button, select {
  padding: 9px 16px;
  max-height: 40px;
  border-color: #254cdd;
  color: #254cdd;
  background-color: rgba(220, 220, 220, 0.224);
  border-radius: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor:pointer;
}

#title {
  padding-top: 32px;
}

#status {
  color:red;
}

.uploadFile {
  border: 4px dashed rgba(4, 4, 5, 0.1);
  border-radius: 16px;
  text-align: center;
  padding: 32px 60px;
}

#walletButton {
  text-align: right;
  float:right;
  margin-bottom: 10px;
  line-height: 16px;
}

#mintButton {
  margin-top: 40px;
  background-color: #254cdd;
  color:white;
}

#mintButton2 {
  background-color: #254cdd;
  color:white;
}


.mintContainer {
  margin: auto;
  display: flex;
  width: 100%;
  padding: 10px 50px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.woolfGallery {
  margin: auto;
  width: 95%;
  max-height: -webkit-max-content;
  max-height: max-content;
  min-height: 150px;
  /* padding: 10px 10px; */
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  border: 4px solid rgba(129, 120, 81, 0.859);
  background-color: rgba(251, 246, 220, 0.547);
  /* border-radius: 16px; */
}

.woolfFrame {
  width: 100px;
  height: 100px;
  padding: 10px;
  font-size: 10px;
  text-align: center;
}

.woolfFrame2 {
  width: 70%;
  margin: auto;
  height: -webkit-max-content;
  height: max-content;
  min-height: 300px;
  padding: 10px;
  font-size: 10px;
  text-align: center;
  border: 1px solid rgba(204, 204, 204, 0.859);
}

.woolfFrame3 {
  width: 90%;
  margin: auto;
  height: -webkit-max-content;
  height: max-content;
  min-height: 300px;
  padding: 10px;
  font-size: 10px;
  text-align: center;
  background-color: rgba(184, 183, 183, 0.507);
}

.brokenconsolefont{
  font-family: BrokenConsole-Bold,Helvetica,sans-serif;
}

.woolfCaption {
  height: 20px;
  text-align: center;
  justify-content: center;
  font-size: 12px;
  color: rgb(50, 50, 50);
}

.woolfCaption2 {
  height: 40px;
  text-align: center;
  justify-content: center;
  font-size: 14px;
  color: rgb(50, 50, 50);
}

.testContainer {
  width:100%;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  /* border: 2px solid rgba(244, 9, 64, 0.859);
  border-radius: 10px; */
  background-color: rgb(233, 241, 255);
  padding: 10px 0px 10px 0px; 
  display: flex;
}

.woolfGallery2 {
  text-align: center;
  width: 100%;
  max-height: -webkit-max-content;
  max-height: max-content;
}

.statsFrame {
  width: 95%;
  margin: auto;
  padding: 15px 15px 0px 15px;
}

.statsFrame table{
  width: 100%;
  padding: 10px;
  border:none;
  border-spacing: 0px 4px;
  background-color: rgb(233, 241, 255);;
}

.statsFrame td{
  text-align: center;
  border: none;
}

.statsFrame th{
  border: none;
  padding-left: 30px;
}

.statsFrame tr{
  background-color: rgb(251 246 220);
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 15px;
  border: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

